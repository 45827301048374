// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const cn = {
    demo: {
        title: `Chinese`,
        introduction: `Lorem Ipsum 只是印刷和排版行业的虚拟文本。 自 1500 年代以来，Lorem Ipsum 一直是行业的标准虚拟文本，当时一位不知名的印刷商采用了一种类型的厨房并将其加扰以制作类型样本书。 它不仅经历了五个世纪，而且经历了电子排版的飞跃，基本保持不变。 它在 1960 年代随着包含 Lorem Ipsum 段落的 Letraset 表的发布而流行起来，最近还随着 Aldus PageMaker 等桌面出版软件（包括 Lorem Ipsum 的版本）而普及.`,
    },
    docs: {
        hi: `你好`,
        description: `需要帮忙？\n 请查看我们的文档.`,
        documentation: `文件`,
    },
    app: `应用程序`,
    user: `用户`,
    list: `列表`,
    edit: `编辑`,
    shop: `商店`,
    blog: `博客`,
    post: `邮政`,
    mail: `电子邮件`,
    chat: `聊天`,
    cards: `牌`,
    posts: `帖子`,
    create: `创建`,
    kanban: `登机`,
    general: `一般的`,
    banking: `银行业`,
    booking: `预订`,
    profile: `轮廓`,
    account: `帐户`,
    product: `产品`,
    invoice: `发票`,
    details: `细节`,
    checkout: `退房`,
    calendar: `日历`,
    analytics: `分析`,
    ecommerce: `电子商务`,
    management: `管理`,
    menu_level_1: `菜单级别 1`,
    menu_level_2a: `菜单级别 2a`,
    menu_level_2b: `菜单级别 2b`,
    menu_level_3a: `菜单级别 3a`,
    menu_level_3b: `菜单级别 3b`,
    menu_level_4a: `菜单级别 4a`,
    menu_level_4b: `菜单级别 4b`,
    item_disabled: `项目已禁用`,
    item_label: `项目标签`,
    item_caption: `项目标题`,
    item_external_link: `项目外部链接`,
    description: `描述`,
    other_cases: `其他情况`,
    item_by_roles: `按角色逐项`,
    only_admin_can_see_this_item: `只有管理员可以看到这个项目`,
};

export default cn;
