// i18n
import './locales/i18n';

// highlight
import './utils/highlight';

// scroll bar
import 'simplebar/src/simplebar.css';

// lightbox
// import 'react-image-lightbox/style.css';

// map
import 'mapbox-gl/dist/mapbox-gl.css';

// editor
import 'react-quill/dist/quill.snow.css';

// slick-carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
// import * as Sentry from '@sentry/react';
// import { BrowserTracing } from '@sentry/tracing';
import { HelmetProvider } from 'react-helmet-async';
// import { Amplify, Logger } from 'aws-amplify';

import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';

// redux
import { store, persistor } from './redux/store';
// contexts
import { SettingsProvider } from './contexts/SettingsContext';
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';

// import { AuthProvider } from './contexts/AwsCognitoContext';
// import { AuthProvider } from './contexts/JWTContext';

//
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
// import { initSentry } from './libs/errorLib';

// initSentry();
// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root'));

// Amplify.configure({
//     Auth: {
//         mandatorySignIn: true,
//         region: config.cognito.REGION,
//         userPoolId: config.cognito.USER_POOL_ID,
//         identityPoolId: config.cognito.IDENTITY_POOL_ID,
//         userPoolWebClientId: config.cognito.APP_CLIENT_ID,
//     },
//     Storage: {
//         region: config.s3.REGION,
//         bucket: config.s3.BUCKET,
//         identityPoolId: config.cognito.IDENTITY_POOL_ID,
//     },
//     API: {
//         endpoints: [
//             {
//                 name: 'crpm',
//                 endpoint: config.apiGateway.URL,
//                 region: config.apiGateway.REGION,
//             },
//         ],
//     },
// });

// Sentry.init({
//     dsn: 'https://cf53febebc5a4737a326b0be464a15ad@o4503967070748672.ingest.sentry.io/4503967079268355',
//     integrations: [new BrowserTracing()],

//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production
//     tracesSampleRate: 1.0,
// });

root.render(
    // <AuthProvider>
        <HelmetProvider>
            <ReduxProvider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <SettingsProvider>
                        <CollapseDrawerProvider>
                            <BrowserRouter>
                                <App />
                            </BrowserRouter>
                        </CollapseDrawerProvider>
                    </SettingsProvider>
                </PersistGate>
            </ReduxProvider>
        </HelmetProvider>
    // </AuthProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
