import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
// @mui
import { alpha, styled } from '@mui/material/styles';
import { Box, List, Link, Drawer, Collapse, ListItemText, ListItemIcon, ListItemButton, Grid, ListItem } from '@mui/material';
// config
import { NAVBAR } from '../../config';
// components
import Logo from '../../components/Logo';
import Iconify from '../../components/Iconify';
import Scrollbar from '../../components/Scrollbar';
import { IconButtonAnimate } from '../../components/animate';
import { NavSectionVertical } from '../../components/nav-section';

// ----------------------------------------------------------------------

const ListItemStyle = styled(ListItemButton)(({ theme }) => ({
    ...theme.typography.body2,
    textTransform: 'capitalize',
    height: NAVBAR.DASHBOARD_ITEM_ROOT_HEIGHT,
    color: theme.palette.text.secondary,
}));

// ----------------------------------------------------------------------

MenuMobile.propTypes = {
    isOffset: PropTypes.bool,
    isHome: PropTypes.bool,
    navConfig: PropTypes.array,
};

export default function MenuMobile({ isOffset, isHome, navConfig }) {
    const { pathname } = useLocation();

    const [open, setOpen] = useState(false);

    const [drawerOpen, setDrawerOpen] = useState(false);

    useEffect(() => {
        if (drawerOpen) {
            handleDrawerClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    const handleOpen = () => {
        setOpen(!open);
    };

    const handleDrawerOpen = () => {
        setDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setDrawerOpen(false);
    };

    return (
        <>
            <IconButtonAnimate
                onClick={handleDrawerOpen}
                sx={{
                    ml: 1,
                    ...(isHome && { color: 'black' }),
                    ...(isOffset && { color: 'text.primary' }),
                }}
            >
                <Iconify icon={'eva:menu-2-fill'} />
            </IconButtonAnimate>

            <Drawer
                open={drawerOpen}
                onClose={handleDrawerClose}
                ModalProps={{ keepMounted: true }}
                PaperProps={{ sx: { pb: 5, width: 260 } }}
            >
                <Scrollbar>
                    <Logo sx={{ mx: 2.5, my: 3, width: 120 }} />

                    <List disablePadding>
                        {navConfig.map(link => (
                            <MenuMobileItem key={link.title} item={link} isOpen={open} onOpen={handleOpen} />
                        ))}
                    </List>
                </Scrollbar>
            </Drawer>
        </>
    );
}

// ----------------------------------------------------------------------

MenuMobileItem.propTypes = {
    isOpen: PropTypes.bool,
    item: PropTypes.shape({
        children: PropTypes.array,
        icon: PropTypes.any,
        path: PropTypes.string,
        title: PropTypes.string,
    }),
    onOpen: PropTypes.func,
};

const SubLinkStyle = styled(props => (
    <ListItem sx={{ p: 0, pl:3 }}>
        <Link rel="noopener" {...props}>
            {props.children}
        </Link>
    </ListItem>
))(({ theme }) => ({
    ...theme.typography.body2,
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(3),
    color: theme.palette.text.secondary,
    transition: theme.transitions.create('color'),
    '&:hover': {
        color: theme.palette.text.primary,
    },
}));


function MenuMobileItem({ item, isOpen, onOpen }) {
    const { title, path, icon, children } = item;

    if (children) {
        return (
            <>
                <ListItemStyle onClick={onOpen}>
                    <ListItemIcon>{icon}</ListItemIcon>
                    <ListItemText disableTypography primary={title} />
                    <Iconify
                        icon={isOpen ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
                        sx={{ width: 16, height: 16, ml: 1 }}
                    />
                </ListItemStyle>

                <Collapse in={isOpen} unmountOnExit>
                    <Box sx={{ display: 'flex', flexDirection: 'column-reverse' }}>
                        {/* <NavSectionVertical
                            navConfig={children}
                            sx={{
                               
                            }}
                        /> */}

{children.map((list, index) => {
                            const { subheader, items } = list;
                            return (
                                <Grid key={index} item xs={12} md={subheader === 'Dashboard' ? 6 : 2}>
                                    <List disablePadding>
                                        {/* <ListSubheader
                                            disableSticky
                                            disableGutters
                                            sx={{
                                                display: 'flex',
                                                lineHeight: 'unset',
                                                alignItems: 'center',
                                                color: 'text.primary',
                                                typography: 'overline',
                                            }}
                                        >
                                            <IconBullet type="subheader" /> {subheader}
                                        </ListSubheader> */}

                                        {items.map(item => (
                                            <SubLinkStyle
                                                key={item.title}
                                                href={item.path}
                                                sx={{
                                                    // ...(isActive(item.path) && {
                                                    //     color: 'text.primary',
                                                    //     typography: 'subtitle2',
                                                    // }),
                                                }}
                                            >
                                                {item.title }
                                            </SubLinkStyle>
                                        ))}
                                    </List>
                                </Grid>
                            );
                        })}
                    </Box>
                </Collapse>
            </>
        );
    }

    if (title === 'Documentation') {
        return (
            <Link href={path} target="_blank" rel="noopener" underline="none">
                <ListItemStyle>
                    <ListItemIcon>{icon}</ListItemIcon>
                    <ListItemText disableTypography primary={title} />
                </ListItemStyle>
            </Link>
        );
    }

    return (
        <ListItemStyle
            to={path}
            component={RouterLink}
            end={path === '/'}
            sx={{
                '&.active': {
                    color: 'primary.main',
                    fontWeight: 'fontWeightMedium',
                    bgcolor: theme => alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
                },
            }}
        >
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText disableTypography primary={title} />
        </ListItemStyle>
    );
}
