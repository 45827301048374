// @mui
import { enUS, frFR, zhCN, viVN, arSD } from '@mui/material/locale';
// routes
import { PATH_DASHBOARD } from './routes/paths';
import pic1 from './assets/crpmPics/Mask_Group_5.png';
import pic2 from './assets/crpmPics/Mask_Group_6.png';
// API
// ----------------------------------------------------------------------

// const config = {
//     // Backend config
//     s3: {
//         REGION: process.env.REACT_APP_REGION,
//         BUCKET: process.env.REACT_APP_BUCKET,
//     },
//     apiGateway: {
//         REGION: process.env.REACT_APP_REGION,
//         URL: process.env.REACT_APP_API_URL,
//     },
//     cognito: {
//         REGION: process.env.REACT_APP_REGION,
//         USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID,
//         APP_CLIENT_ID: process.env.REACT_APP_USER_POOL_CLIENT_ID,
//         IDENTITY_POOL_ID: process.env.REACT_APP_IDENTITY_POOL_ID,
//     },
//     SENTRY_DSN: 'https://cf53febebc5a4737a326b0be464a15ad@o4503967070748672.ingest.sentry.io/4503967079268355',
// };

export const HOST_API = process.env.REACT_APP_HOST_API_KEY || '';

export const COGNITO_API = {
    userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
    clientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
};

export const MAPBOX_API = process.env.REACT_APP_MAPBOX_API;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = PATH_DASHBOARD.general.app; // as '/dashboard/app'

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
    MOBILE_HEIGHT: 64,
    MAIN_DESKTOP_HEIGHT: 88,
    DASHBOARD_DESKTOP_HEIGHT: 92,
    DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
};

export const NAVBAR = {
    BASE_WIDTH: 260,
    DASHBOARD_WIDTH: 280,
    DASHBOARD_COLLAPSE_WIDTH: 88,
    //
    DASHBOARD_ITEM_ROOT_HEIGHT: 48,
    DASHBOARD_ITEM_SUB_HEIGHT: 40,
    DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
    NAVBAR_ITEM: 22,
    NAVBAR_ITEM_HORIZONTAL: 20,
};

// SETTINGS
// Please remove `localStorage` when you change settings.
// ----------------------------------------------------------------------

export const defaultSettings = {
    themeMode: 'light',
    themeDirection: 'ltr',
    themeContrast: 'default',
    themeLayout: 'horizontal',
    themeColorPresets: 'default',
    themeStretch: false,
};

export const BLOG_POSTS = [
    {
        cover: pic1,
        title: 'How does monitoring the blood pressure levels of a senior citizen frequently save medicare?',
        view: 120,
        author: "Shiva Rajagopalan",
        id: '1',
        tags: ["Monitor", "Blood Pressure", "Medicare", "Senior Citizen"],
        content: `<h4>Monitoring blood pressure levels of a senior citizen frequently through remote patient monitoring (RPM) can help save Medicare in a few ways:</h4><br><ul>
        <li><em><b>Early detection and management of hypertension:</b></em> High blood pressure is a major risk factor for heart disease and stroke, which are leading causes of hospitalization and death among senior citizens. By monitoring blood pressure levels frequently, healthcare providers can detect hypertension early and take steps to manage it, which can reduce the risk of complications and hospitalizations.</li>
        <li><em><b>Reduced hospital readmissions:</b></em> Hospital readmissions are a significant cost to Medicare. By monitoring blood pressure levels frequently and taking action to manage hypertension, RPM can help reduce the risk of hospital readmissions for hypertension-related complications.</li>
        <li><em><b>Better medication management:</b></em> RPM can also help with medication management for hypertension. By receiving frequent blood pressure readings, healthcare providers can adjust medication dosages as needed to help control blood pressure and reduce the risk of complications.</li>
        <li><em><b>Reduced need for office visits:</b></em> RPM can also help reduce the need for office visits, which can save Medicare costs. This is particularly beneficial for senior citizens who may have difficulty traveling to a clinic or hospital.</li>
        <li><em><b>Improved patient outcomes:</b></em> By monitoring blood pressure frequently and taking action to manage hypertension, RPM can also improve patient outcomes by reducing the risk of complications and hospitalizations, which can ultimately lead to cost savings for Medicare.</li>
        </ul>
        <p style="margin-top: 40px">It's also important to note that RPM is not only beneficial for hypertension, it also helps in monitoring and managing other chronic conditions, such as diabetes, heart failure and COPD, which are common among senior citizens. By monitoring these conditions remotely, healthcare providers can take steps to prevent complications and hospitalizations, which can save Medicare costs.</p>`
    },
    {
        cover: pic2,
        title: 'How do doctors benefit from remote patient monitoring?',
        view: 85,
        author: "Shiva Rajagopalan",
        id: '2',
        tags: ["Monitor", "Blood Pressure", "Medicare", "Senior Citizen"],
        content: `<h4>Remote patient monitoring (RPM) allows doctors to gather and review patient health data remotely, using various devices and technologies such as smartphones, wearables, and home-based monitoring devices. This can provide doctors with a more complete picture of their patients’ health, which can help them to make more informed decisions about treatment and care. Here are a few ways doctors benefit from remote patient monitoring:</h4>
        <br><ul>
        <li><em><b>Improved patient outcomes:</b></em> RPM can help doctors to identify health problems early on, before they become severe. This can lead to earlier interventions and better outcomes for patients.</li>
        <li><em><b>Increased patient engagement:</b></em> RPM can help to increase patient engagement by allowing patients to take a more active role in their own health care. This can lead to better adherence to treatment plans and better outcomes.</li>
        <li><em><b>Increased efficiency:</b></em> RPM can help doctors to manage their patients more efficiently, by allowing them to monitor multiple patients remotely, rather than having to see them in person.</li>
        <li><em><b>Increased access to care:</b></em> RPM can help to increase access to care for patients in remote or underserved areas, who may not have access to specialists or other healthcare providers.</li>
        <li><em><b>Cost savings:</b></em> RPM can help to reduce the cost of healthcare by reducing the need for in-person visits, hospitalizations, and other costly interventions.</li>
        <li><em><b>Real-time monitoring:</b></em> RPM allows doctors to monitor patients in real time, which can help them to quickly identify and address any issues that arise, which can improve the overall quality of care.</li>
        <li><em><b>Better patient-doctor communication:</b></em> RPM allows doctors to communicate with patients more effectively, which can help them to build trust and establish better relationships.</li>
        </ul>
        <p style="margin-top: 40px">Overall, remote patient monitoring can help doctors to provide better care to their patients, while also improving the efficiency and cost-effectiveness of the healthcare system.</p>`
    },
];

// MULTI LANGUAGES
// Please remove `localStorage` when you change settings.
// ----------------------------------------------------------------------

export const allLangs = [
    {
        label: 'English',
        value: 'en',
        systemValue: enUS,
        icon: '/assets/icons/flags/ic_flag_en.svg',
    },
    {
        label: 'French',
        value: 'fr',
        systemValue: frFR,
        icon: '/assets/icons/flags/ic_flag_fr.svg',
    },
    {
        label: 'Vietnamese',
        value: 'vn',
        systemValue: viVN,
        icon: '/assets/icons/flags/ic_flag_vn.svg',
    },
    {
        label: 'Chinese',
        value: 'cn',
        systemValue: zhCN,
        icon: '/assets/icons/flags/ic_flag_cn.svg',
    },
    {
        label: 'Arabic (Sudan)',
        value: 'ar',
        systemValue: arSD,
        icon: '/assets/icons/flags/ic_flag_sa.svg',
    },
];

export const defaultLang = allLangs[0]; // English
