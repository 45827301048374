// routes
import { PATH_AUTH, PATH_DOCS, PATH_PAGE, PATH_DASHBOARD } from '../../routes/paths';
// components
// import { PATH_AFTER_LOGIN } from '../../config';
// components
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const ICON_SIZE = {
    width: 22,
    height: 22,
};

const menuConfig = [
    {
        title: 'Home',
        icon: <Iconify icon={'eva:home-fill'} {...ICON_SIZE} />,
        path: '/',
    },
    {
        title: 'Resources',
        path: PATH_PAGE.resources,
        icon: <Iconify icon={'eva:file-fill'} {...ICON_SIZE} />,
        children: [
            {
                items: [
                    { title: 'Doctors', path: PATH_PAGE.doctors },
                    { title: 'Patients', path: PATH_PAGE.patients },
                    { title: 'Blog', path: PATH_PAGE.blog },
                ],
              },
        ],
    },
    // {
    //     title: 'How it works',
    //     path: '/',
    //     icon: <Iconify icon={'eva:file-fill'} {...ICON_SIZE} />,
    // },
    {
        title: 'About',
        path: PATH_PAGE.about,
        icon: <Iconify icon={'eva:file-fill'} {...ICON_SIZE} />,
    },
    {
        title: 'FAQs',
        path: PATH_PAGE.faqs,
        icon: <Iconify icon={'eva:file-fill'} {...ICON_SIZE} />,
    },
];

export default menuConfig;
