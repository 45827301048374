import PropTypes from 'prop-types';
import { m } from 'framer-motion';
import { useState, useEffect } from 'react';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Link, Grid, List, Stack, Popover, ListItem, ListSubheader, CardActionArea } from '@mui/material';
// components
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const LinkStyle = styled(Link)(({ theme }) => ({
    ...theme.typography.subtitle2,
    color: theme.palette.text.primary,
    marginRight: theme.spacing(5),
    transition: theme.transitions.create('opacity', {
        duration: theme.transitions.duration.shorter,
    }),
    '&:hover': {
        opacity: 0.48,
        textDecoration: 'none',
    },
}));

const SubLinkStyle = styled(props => (
    <ListItem sx={{ p: 0 }}>
        <Link rel="noopener" {...props}>
            {props.children}
        </Link>
    </ListItem>
))(({ theme }) => ({
    ...theme.typography.body2,
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(3),
    color: theme.palette.text.secondary,
    transition: theme.transitions.create('color'),
    '&:hover': {
        color: theme.palette.text.primary,
    },
}));

// ----------------------------------------------------------------------

MenuDesktop.propTypes = {
    isHome: PropTypes.bool,
    isOffset: PropTypes.bool,
    navConfig: PropTypes.array,
};

export default function MenuDesktop({ isOffset, isHome, navConfig }) {
    const { pathname } = useLocation();
    const [anchorEl, setAnchorEl] = useState(null);

    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (open) {
            handleClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    const handleOpen = (event) => {
        console.log(event.currentTarget.getBoundingClientRect());
        setAnchorEl(event.currentTarget);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Stack direction="row">
            {navConfig.map(link => (
                <MenuDesktopItem
                    key={link.title}
                    item={link}
                    isOpen={open}
                    onOpen={handleOpen}
                    onClose={handleClose}
                    isOffset={isOffset}
                    isHome={isHome}
                    anchorEl={anchorEl}
                />
            ))}
        </Stack>
    );
}

// ----------------------------------------------------------------------

MenuDesktopItem.propTypes = {
    isHome: PropTypes.bool,
    isOffset: PropTypes.bool,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onOpen: PropTypes.func,
    item: PropTypes.shape({
        path: PropTypes.string,
        title: PropTypes.string,
        children: PropTypes.array,
    }),
};

function MenuDesktopItem({ item, isHome, isOpen, isOffset, onOpen, onClose, anchorEl }) {
    let leftOffset 
    let topOffset 
    const { pathname } = useLocation();


    const { title, path, children } = item;

    const isActive = path => pathname === path;
   

    if (children) { 
        if(anchorEl){
            const {left, top, height} = anchorEl.getBoundingClientRect();
             leftOffset = left; 
             topOffset = (top + height);
            //  (top + height);
    }
  
        return (
            <>
               <LinkStyle
                    onClick={onOpen}
                    sx={{
                        display: 'flex',
                        cursor: 'pointer',
                        alignItems: 'center',
                        ...(isOffset && { color: 'text.primary' }),
                        ...(isOpen && { opacity: 0.48 }),
                    }}
                >
                    {title}
                    <Iconify
                        icon={isOpen ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'}
                        sx={{ ml: 0.5, width: 16, height: 16 }}
                    />
                </LinkStyle>

                <Popover
                
                    open={isOpen}
                    anchorEl={anchorEl}
                    anchorReference="anchorPosition"
                    anchorPosition={{ top: topOffset, left: leftOffset}}
                    onClose={onClose}
                    PaperProps={{
                        sx: {
                            px: 3,
                            pt: 0,
                            pb: 3,
                            // right: 16,
                            // m: 'auto',
                            // mr: { md: 32, lg: 47, xl: 64},
                            // mt: 2,
                            borderRadius: 2,
                            maxWidth: 'fit-content',
                            boxShadow: theme => theme.customShadows.z24,
                        },
                    }}
                >
                    <Grid container spacing={3}>
                        {children.map((list, index) => {
                            const { subheader, items } = list;
                            return (
                                <Grid key={index} item xs={12} md={subheader === 'Dashboard' ? 6 : 2}>
                                    <List disablePadding>
                                        {/* <ListSubheader
                                            disableSticky
                                            disableGutters
                                            sx={{
                                                display: 'flex',
                                                lineHeight: 'unset',
                                                alignItems: 'center',
                                                color: 'text.primary',
                                                typography: 'overline',
                                            }}
                                        >
                                            <IconBullet type="subheader" /> {subheader}
                                        </ListSubheader> */}

                                        {items.map(item => (
                                            <SubLinkStyle
                                                key={item.title}
                                                href={item.path}
                                                sx={{
                                                    ...(isActive(item.path) && {
                                                        color: 'text.primary',
                                                        typography: 'subtitle2',
                                                    }),
                                                }}
                                            >
                                                {item.title }
                                            </SubLinkStyle>
                                        ))}
                                    </List>
                                </Grid>
                            );
                        })}
                    </Grid>
                </Popover>
            </>
        );
    }

    return (
        <LinkStyle
            to={path}
            component={RouterLink}
            end={path === '/'}
            sx={{
                ...(isHome && { color: 'common.primary' }),
                ...(isOffset && { color: 'text.primary' }),
                '&.active': {
                    color: 'primary.main',
                },
            }}
        >
            {title}
        </LinkStyle>
    );
}

// ----------------------------------------------------------------------

IconBullet.propTypes = {
    type: PropTypes.oneOf(['item', 'subheader']),
};

function IconBullet({ type = 'item' }) {
    return (
        <Box sx={{ width: 24, height: 16, display: 'flex', alignItems: 'center' }}>
            <Box
                component="span"
                sx={{
                    ml: '2px',
                    width: 4,
                    height: 4,
                    borderRadius: '50%',
                    bgcolor: 'currentColor',
                    ...(type !== 'item' && { ml: 0, width: 8, height: 2, borderRadius: 2 }),
                }}
            />
        </Box>
    );
}
